import React, { useEffect } from 'react'
import { CheckCircle, Warning, X, XCircle } from '@phosphor-icons/react'

import { useNotifications } from '@store/notifications'

// const slideIn = keyframes
//   0% {
//     -webkit-transform: translateX(100%);
//     transform: translateX(100%);
//     opacity: 0;
//   }
//   100% {
//     -webkit-transform: translateX(0);
//     transform: translateX(0);
//     opacity: 1;
//   }
// `

// const NotificationContainer = styled.div<{ $type: NotificationType }>`
//     position: fixed;
//     bottom: 75px;
//     right: 10px;
//     width: 350px;
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
//     padding: 16px;
//     border-radius: 8px;
//     box-shadow: 3px 3px 10px 5px ${({ theme }) => theme.colors.ui.cloud};
//     background-color: ${({ theme }) => theme.colors.ui.white};
//     z-index: 9999;
//     animation: ${slideIn} 0.5s ease-out;
//     ${({ theme }) => theme.breakpoints.up('md')} {
//         right: 75px;
//     }
// `

export const Notifications = () => {
    const { isOpen, title, description, type, duration, isCloseable, closeNotification } = useNotifications()

    useEffect(() => {
        let timeout: NodeJS.Timeout | null = null

        if (isOpen && duration !== 0) {
            timeout = setTimeout(() => {
                closeNotification()
            }, duration)
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [isOpen])

    if (isOpen) {
        let Icon
        switch (type) {
            case 'error':
                Icon = XCircle
                break
            case 'warn':
                Icon = Warning
                break

            case 'success':
            default:
                Icon = CheckCircle
        }

        return (
            <div>
                <Icon size={20} display="block" weight="fill" />
                {title}

                {description && <>{description}</>}
                {isCloseable && (
                    <span>
                        <X
                            size={20}
                            onClick={closeNotification}
                            style={{
                                cursor: 'pointer'
                            }}
                        />
                    </span>
                )}
            </div>
            /* <NotificationContainer $type={type}>
                <Stack $direction="row" $spacing={2} $alignItems="flex-start" style={{ width: '100%' }}>
                    <div>
                        <Icon size={20} display="block" color={color} weight="fill" />
                    </div>
                    <Stack $spacing={1} style={{ flexGrow: 1 }}>
                        <Text variant="body2" weight="600">
                            {title}
                        </Text>
                        {description && <Text variant="body2">{description}</Text>}
                    </Stack>
                    {isCloseable && (
                        <span>
                            <X
                                size={20}
                                onClick={closeNotification}
                                style={{
                                    cursor: 'pointer'
                                }}
                            />
                        </span>
                    )}
                </Stack>
            </NotificationContainer> */
        )
    }
    return <></>
}
