import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { ConfigProvider } from 'antd'

import { UnauthenticatedLayout } from '@layouts/UnauthenticatedLayout'
import { AuthenticatedLayout } from '@layouts/AuthenticatedLayout'

import { Notifications } from '@components/Notifications'
import { initializeSentry } from '@connectors/SentryClient'
import { AuthProvider, ProtectedRoute } from '@hooks/useAuth'

import { ErrorPage } from './pages/Error'
import { DashboardPage } from './pages/Dashboard'
import { LoginPage } from './pages/Login'
import { UserListPage } from './pages/Users/UserListPage'

import { ReportsPage } from './pages/Reports/ReportsPage'
import { LogoutPage } from './pages/Logout'

if (import.meta.env.VITE_NODE_ENV === 'production') {
    initializeSentry()
}

const router = createBrowserRouter(
    [
        {
            path: '/login',
            element: (
                <UnauthenticatedLayout>
                    <LoginPage />
                </UnauthenticatedLayout>
            ),
            errorElement: <ErrorPage />
        },
        {
            path: '/',
            element: (
                <ProtectedRoute>
                    <AuthenticatedLayout>
                        <DashboardPage />
                    </AuthenticatedLayout>
                </ProtectedRoute>
            ),
            errorElement: <ErrorPage />
        },
        {
            path: '/users',
            element: (
                <ProtectedRoute>
                    <AuthenticatedLayout>
                        <UserListPage />
                    </AuthenticatedLayout>
                </ProtectedRoute>
            ),
            errorElement: <ErrorPage />
        },
        {
            path: '/reports',
            element: (
                <ProtectedRoute>
                    <AuthenticatedLayout>
                        <ReportsPage />
                    </AuthenticatedLayout>
                </ProtectedRoute>
            ),
            errorElement: <ErrorPage />
        },
        {
            path: '/logout',
            element: (
                <UnauthenticatedLayout>
                    <LogoutPage />
                </UnauthenticatedLayout>
            ),
            errorElement: <ErrorPage />
        }
    ],
    {
        basename: '/'
    }
)

const Main = () => {
    return (
        <>
            <Notifications />
            <RouterProvider router={router} />
        </>
    )
}

// Important: since strict mode is enabled in dev env, it double the renders.
// For some reason pixi board is not destroyed properly on second rerndering..
// I disabled strict mode until we figure this out..

// As well: In development mode, React will rethrow errors caught within an error boundary.
// This will result in errors being reported twice to Sentry with the above setup, but this won't occur in the production build.
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
        <AuthProvider>
            <ConfigProvider
                theme={{
                    token: {}
                }}
            >
                <Main />
            </ConfigProvider>
        </AuthProvider>
    </Sentry.ErrorBoundary>
)
