import { useEffect, useState } from 'react'

import { ReportsService } from '@services/ReportsService'

export const ReportsPage = () => {
    const [numConnected, setNumConnected] = useState<number>(0)

    useEffect(() => {
        const fetch = async () => {
            try {
                const results = await ReportsService.get()
                setNumConnected(results.numberOfActiveConnections)
            } catch (err) {
                console.error('Unable to retrieve reports info')
            }
        }

        fetch()
    }, [])

    return (
        <>
            <h1>Temporary Reports</h1>
            <p>
                The current number of connected clients is <strong>{numConnected}</strong>
            </p>
        </>
    )
}
