import { useEffect, useState } from 'react'
import date from 'date-and-time'
import { Button, Space, Table, type TableProps } from 'antd'

import { UserService } from '../../services/UserService'
import { EditUserModal } from './EditUserModal'

interface DataType {
    firstName: string
    lastName: string
    meta: any
    userId: string
    createdAt: Date
}

export const UserListPage = () => {
    const [data, setData] = useState()
    const [selectedId, setSelectedId] = useState<string | null>(null)

    useEffect(() => {
        const fetch = async () => {
            const results = await UserService.list()
            setData(results)
        }

        fetch()
    }, [])

    const openEditModal = (userId: string) => {
        setSelectedId(userId)
    }

    const closeEditModal = () => {
        setSelectedId(null)
    }

    const columns: TableProps<DataType>['columns'] = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName'
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName'
        },
        {
            title: 'Created',
            key: 'created',
            dataIndex: 'createdAt',
            render: (_, { createdAt }) => <>{date.format(new Date(createdAt), 'YYYY/MM/DD HH:mm:ss')} </>
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="link"
                        onClick={() => {
                            openEditModal(record.userId)
                        }}
                    >
                        Edit
                    </Button>
                </Space>
            )
        }
    ]

    return (
        <div>
            <h1>Users</h1>
            <Table columns={columns} dataSource={data} rowKey="userId" />
            <EditUserModal selectedId={selectedId} onClose={closeEditModal} />
        </div>
    )
}
