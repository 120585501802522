import { httpClient } from '@connectors/HttpClient'

import { type User } from '../types'

export type UserParams = Omit<User, 'userId'>

export const UserService = {
    list: async (): Promise<any> => {
        const response = await httpClient.axios.get('/users')
        return response.data
    },
    get: async (id: string): Promise<any> => {
        const response = await httpClient.axios.get(`/users/${id}`)
        return response.data
    },
    update: async (id: string, params: UserParams): Promise<any> => {
        const response = await httpClient.axios.put(`/users/${id}`, params)
        return response.data
    }
}
