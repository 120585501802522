import React, { useEffect, useState } from 'react'
import {
    Button,
    Form,
    type FormProps,
    Input,
    InputNumber,
    Modal,
    Select,
    Space,
    type NotificationArgsProps,
    notification
} from 'antd'

import { type UserParams, UserService } from '@services/UserService'
import { currency } from '@constants/money'
import { User } from '../../types'

type ModalProps = {
    selectedId: string | null
    onClose: () => void
}

type FieldType = {
    firstName?: string
    lastName?: string
    maxBoards?: number
    currencyType?: string
}

type NotificationPlacement = NotificationArgsProps['placement']
type NotificationType = 'success' | 'error'

export const EditUserModal: React.FC<ModalProps> = ({ selectedId, onClose }) => {
    const [user, setUser] = useState<User>()
    const [api, contextHolder] = notification.useNotification()

    const openNotification = (
        placement: NotificationPlacement,
        type: NotificationType,
        message: string,
        description?: string
    ) => {
        api[type]({
            message,
            description,
            placement
        })
    }

    useEffect(() => {
        const fetch = async () => {
            try {
                const results = await UserService.get(selectedId!)
                setUser(results)
            } catch (err) {
                console.error('Error retrieving user information', err)
            }
        }

        if (selectedId) {
            fetch()
        }
    }, [selectedId])

    const onFinish: FormProps<FieldType>['onFinish'] = async values => {
        const body: UserParams = {
            firstName: values.firstName!,
            lastName: values.lastName!,
            meta: {
                maxBoards: values.maxBoards!,
                currencyType: values.currencyType
            }
        }

        try {
            await UserService.update(selectedId!, body)
            openNotification('top', 'success', 'User updated!')
            onClose()
        } catch (err: any) {
            openNotification('top', 'error', 'User update failed!', err.toString())
        }
    }

    const isOpen = !!selectedId

    if (!user) {
        return null
    }

    return (
        <Modal open={isOpen} footer={[]} onCancel={onClose}>
            {
                contextHolder // not 100% this is supposed to go there but following the example and it works
            }
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                disabled={false}
                onFinish={onFinish}
            >
                <h3>Edit User</h3>
                <Form.Item label="User ID">
                    <span>{user.userId}</span>
                </Form.Item>

                <Form.Item
                    label="First Name"
                    name="firstName"
                    initialValue={user.firstName}
                    rules={[{ required: true, message: 'Please enter a first name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Last Name"
                    name="lastName"
                    initialValue={user.lastName}
                    rules={[{ required: true, message: 'Please enter a last name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Email">
                    <Input disabled placeholder="Not implemented" />
                </Form.Item>
                <Form.Item
                    label="Max Boards"
                    name="maxBoards"
                    initialValue={user.meta.maxBoards}
                    rules={[{ required: true, message: 'Please enter a maximum board number' }]}
                >
                    <InputNumber />
                </Form.Item>

                <Form.Item label="Select" name="currencyType" initialValue={user.meta.currencyType}>
                    <Select>
                        {currency.map(c => (
                            <Select.Option key={c.currencyType} value={c.currencyType}>
                                {c.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    )
}
