import { create } from 'zustand'

interface NotificationState {
    isOpen: boolean
    title: string
    description: string
    type: NotificationType
    duration: number
    isCloseable: boolean
    actions: {
        showNotification: (options: {
            title: string
            description?: string
            type?: NotificationType
            duration?: number
            isCloseable?: boolean
        }) => void
        closeNotification: () => void
    }
}
export const useNotificationsStore = create<NotificationState>(set => ({
    isOpen: false,
    title: '',
    description: '',
    type: 'success',
    duration: 3500,
    isCloseable: true,
    actions: {
        showNotification: ({ title, description = '', type = 'success', duration = 5000, isCloseable = true }) => {
            set(() => ({
                isOpen: true,
                title,
                description,
                type,
                duration,
                isCloseable
            }))
        },
        closeNotification: () => set(() => ({ isOpen: false, message: '' }))
    }
}))

export type NotificationType = 'success' | 'error' | 'warn'

export const useNotifications = () =>
    useNotificationsStore(state => {
        const {
            isOpen,
            title,
            description,
            type,
            duration,
            isCloseable,
            actions: { closeNotification, showNotification }
        } = state
        return { isOpen, title, description, type, duration, isCloseable, closeNotification, showNotification }
    })
